// Generated by Framer (9e4a47a)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["oGr647WK6", "JrhMp2YKG"];

const serializationHash = "framer-NWcbM"

const variantClassNames = {JrhMp2YKG: "framer-v-tef5nn", oGr647WK6: "framer-v-jyo7og"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "oGr647WK6", "Variant 2": "JrhMp2YKG"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "oGr647WK6"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "oGr647WK6", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fit", intrinsicHeight: 275, intrinsicWidth: 427, loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 1868, pixelWidth: 5555, positionX: "center", positionY: "center", sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/7qrKz7NDyhjkojo1YUspVdwFUyM.png?scale-down-to=512", srcSet: "https://framerusercontent.com/images/7qrKz7NDyhjkojo1YUspVdwFUyM.png?scale-down-to=512 512w,https://framerusercontent.com/images/7qrKz7NDyhjkojo1YUspVdwFUyM.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/7qrKz7NDyhjkojo1YUspVdwFUyM.png?scale-down-to=2048 2048w,https://framerusercontent.com/images/7qrKz7NDyhjkojo1YUspVdwFUyM.png?scale-down-to=4096 4096w,https://framerusercontent.com/images/7qrKz7NDyhjkojo1YUspVdwFUyM.png 5555w"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-jyo7og", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"oGr647WK6"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({JrhMp2YKG: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-NWcbM.framer-bf8okc, .framer-NWcbM .framer-bf8okc { display: block; }", ".framer-NWcbM.framer-jyo7og { height: 680px; overflow: hidden; position: relative; width: 1730px; }", ".framer-NWcbM.framer-v-tef5nn.framer-jyo7og { aspect-ratio: 2.543424317617866 / 1; height: var(--framer-aspect-ratio-supported, 275px); width: 700px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 680
 * @framerIntrinsicWidth 1729.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"JrhMp2YKG":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerEV9W1EaO_: React.ComponentType<Props> = withCSS(Component, css, "framer-NWcbM") as typeof Component;
export default FramerEV9W1EaO_;

FramerEV9W1EaO_.displayName = "Inner Logo";

FramerEV9W1EaO_.defaultProps = {height: 680, width: 1729.5};

addPropertyControls(FramerEV9W1EaO_, {variant: {options: ["oGr647WK6", "JrhMp2YKG"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerEV9W1EaO_, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})